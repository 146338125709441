import React from 'react';
import { Wrapper } from './osobennostiTehnologii-styled';



export const ImgBottom = () => {
  return (
    <Wrapper/>
  );
};
